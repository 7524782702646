<template>
  <div class="actions">
    <router-link
      :to="{name: 'detail-group', params: {uid: object.uid}}"
      class="btn btn-primary btn-sm"
    >
      <i class="icofont icofont-eye" />
    </router-link>
    <a
      href="javascript:void(0)"
      class="btn btn-third btn-sm"
      @click.prevent="deleting"
      v-if="is_admin"
    > <i class="icofont icofont-close" /></a>
  </div>
</template>
    
    
    
    <script>
    import { DELETE_GROUPE } from '../../../graphql/group';
    import { mapGetters } from 'vuex';
    export default {
        props: {
            object: {type: Object, required: true}
        },
        data(){
            return {
    
            }
        },
        methods: {
            // activating(active){
            //   let data = {
            //     active
            //   }
            //   this.$apollo.mutate({
            //       mutation: EDIT_USER,
            //       variables: {
            //           "updateUser": {
            //               ...data
            //           },
            //           "uid": this.object.uid
            //       },
            //       update: (d) => {
            //           console.log(d)
            //           console.log(`User ${this.object.fullname} updated successfully`)
            //       }
            //     })
            // },
            deleting(){
              this.$apollo.mutate({
                    mutation: DELETE_GROUPE,
                    variables: {
                        "uid": this.object.uid
                    },
                    update: (data) => {
                        if(data) console.log(`Group ${this.object.uid} deleted successfully`)
                        else console.log(`Error in deletting group ${this.object.uid}`)
                    }
                  })
            }
        },
        computed: {
          ...mapGetters({
            is_super_admin: 'auth/is_super_admin',
            is_admin: 'auth/is_admin',
            is_veto: 'auth/is_veterinaire',
          })
        }
    
    }
    
    </script>
    <style lang="scss">
    .actions{
        a{
            
        }
    }
    </style>